import React from "react";
import { Link } from "gatsby";

export default function Header(props) {
  return (
    <header>
      <h3>{props.headerText}</h3>
      <nav>
        <Link to="/">home</Link>
        &nbsp;|&nbsp;
        <Link to={"/posts"}>posts</Link>
        &nbsp;|&nbsp;
        <Link to={"/instagram"}>instagram</Link>
      </nav>
    </header>
  );
}
