import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./layout.module.css"

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <h1>{data.site.siteMetadata.title}</h1>
        {children}
      </div>
    </div>
  )
}
