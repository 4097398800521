import { Link } from "gatsby";
import React from "react";
import styles from "./pagination.module.css";

export default function Pagination(props) {
  const { pageContext = {} } = props;
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    humanPageNumber,
    pageNumber,
  } = pageContext;

  const pages = [];

  const maxPagesShown = 10;
  const startNum = Math.floor(pageNumber / maxPagesShown) * maxPagesShown;
  const endNum = Math.min(numberOfPages - 1, startNum + maxPagesShown - 1);

  for (let i = startNum; i <= endNum; i++) {
    const pageNum = i + 1;
    const pagePath = pageNum === 1 ? "/instagram" : `/instagram/${pageNum}`;

    pages.push({
      pageNum,
      pagePath,
    });
  }

  return (
    <ul className={styles.pagination}>
      {previousPagePath ? (
        <li>
          <Link to={previousPagePath}>Previous</Link>
        </li>
      ) : null}

      {pages.map(page => (
        <li key={`pg_${page.pageNum}`}>
          {page.pageNum === humanPageNumber ? (
            <strong>{humanPageNumber}</strong>
          ) : (
            <Link to={page.pagePath}>{page.pageNum}</Link>
          )}
        </li>
      ))}
      {nextPagePath ? (
        <li>
          <Link to={nextPagePath}>Next</Link>
        </li>
      ) : null}
    </ul>
  );
}
