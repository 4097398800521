import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import Pagination from "../components/pagination";
import styles from "./instagram-index.module.css";

export default function InstagramPostList({ data, pageContext }) {
  return (
    <Layout>
      <Header headerText={`Instagram`} />
      <Pagination pageContext={pageContext} />
      <div className={styles.photoList}>
        {data.allPostsJson.edges.map(({ node }) => {
          return (
            <div
              key={node.id}
              className={styles.photoListItem}
              style={{
                backgroundImage: `url(${node.media[0].image.publicURL})`,
              }}
              onClick={() => navigate(node.fields.slug)}
            >
              &nbsp;
            </div>
          );
        })}
      </div>
      <Pagination pageContext={pageContext} />
    </Layout>
  );
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allPostsJson(
      sort: { fields: [time], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          text
          time
          username
          shortcode
          media {
            image {
              publicURL
            }
            video {
              publicURL
            }
          }
          avatar
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`;
